<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 管理员
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="handle-del mr10"
          @click="addmenu"
          >添加管理</el-button
        >
        <!-- <el-input v-model="query.name" placeholder="用户名" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button> -->
      </div>
      <el-table :data="tableData" border  class="table" ref="multipleTable" header-cell-class-name="table-header" @selection-change="handleSelectionChange">
        <!-- <template #default="scope">￥{{ scope.row.name }}</template> -->
        <el-table-column prop="id" label="ID" width="66" align="center"></el-table-column>
        <el-table-column prop="user_login" label="用户名" align="center"></el-table-column>
        <el-table-column label="最后登录IP" align="center">
          
          <template #default="{ row }">
            <span>{{
              row.last_login_ip == ''
                ? "-"
                : row.last_login_ip
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="last_login_time" :formatter="timestampToTime" label="最后登录时间" align="center"></el-table-column>
        <el-table-column label="邮箱" align="center">
          <template #default="{ row }">
            <span>{{
              row.user_email == ''
                ? "-"
                : row.user_email
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template #default="{ row }">
            <span>{{
              row.user_status == 0
                ? "禁用"
                : row.user_status == 1
                ? "启用"
                : ""
            }}</span>
              <!-- <el-switch
                v-model="row.user_type"
                on-color="#00A854"
                on-text="启用"
                on-value="1"
                off-color="#F04134"
                off-text="禁用"
                off-value="0"
                @change="changeSwitch(row)"
                >
              </el-switch> -->
              
            
            <!-- <el-switch
                style="display: block"
                v-model="row.user_type"
                active-color="#00A854"
                inactive-color="#ff4949"
                active-text="启用"
                on-value="1"
                inactive-text="禁用"
                off-value="0"
              >
            </el-switch> -->
            
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)" >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
        background layout="total, prev, pager, next" 
        :current-page="hoteldatares.page_index" 
        :page-size="hoteldatares.page_size" 
        :total="pageTotal" 
        @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 添加弹出框 -->
    <el-dialog title="添加管理员账号" v-model="addVisible" width="40%">
      <el-form :model="addform" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
        <el-form-item label="账号" prop="user_login">
          <el-input v-model="addform.user_login"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="user_pass">
          <el-input v-model="addform.user_pass"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="user_email">
          <el-input v-model="addform.user_email"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="user_status">
          <el-radio-group v-model="addform.user_status">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="角色">
          <el-checkbox-group v-model="addform.role_id_arr">
          <el-checkbox v-for="mpb in permissionBeans" :label="mpb.id" :key="mpb.id">{{mpb.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="addmenuFun('addform')">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="40%">
      <el-form :model="editform" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
        <el-form-item label="账号" prop="user_login">
          <el-input v-model="editform.user_login"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="user_pass">
          <el-input v-model="editform.user_pass"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="user_email">
          <el-input v-model="editform.user_email"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="user_status">
          <el-radio-group v-model="editform.user_status">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="角色">

          <el-checkbox-group v-model="editform.role_id_arr">
            <el-checkbox v-for="mpb in permissionBeans" :label="mpb.id" :key="mpb.id">{{mpb.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { add_userdata,role_listdata, user_listdata,delete_userlist,edit_list,get_user_infolist} from "../../api/index";
export default {
  name: "basetable",
  data() {
    return {
      //添加菜单数据
      addform: {
        user_login: this.user_login,
        user_pass: this.password,
        user_email: this.user_email,
        user_status:this.user_status,
        role_id_arr:[]
      },
      editform:{
        user_login: this.user_login,
        user_pass: this.password,
        user_email: this.user_email,
        user_status:this.user_status,
        role_id_arr:[]
      },
      editId:'',
      hoteldatares: {
        page_index: 1,
        page_size: 5,
      },
      rules: {
        user_login: [
          {
            required: true,
            message: "请输入管理员账号",
            trigger: "blur",
          },
        ],
        user_pass: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        user_status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        user_email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
            {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
            message: "请输入正确的邮箱格式，例：***@qq.com或***@163.com",
            trigger: "change",
          }
        ],
     //    role: [
     //      {
     //        required: true,
     //        message: "请选择角色",
     //        trigger: "blur",
     //      }
     //    ],
      },
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      permissionBeans:[],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData() {
     user_listdata(this.hoteldatares).then((res) => {
        this.tableData = res.data.user;
        this.pageTotal = res.data.count;
      });
     role_listdata().then((res) => {
        this.permissionBeans = res.data
     });
    },
    //创建时间格式化
    timestampToTime(row) {
      if(row.last_login_time == 0){
        return "-"
      }else{
        var date = new Date(row.last_login_time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() +1 < 10 ? '0'+(date.getDate()+1) : date.getDate()+1) + ' ';

        var h = (date.getHours() +1 < 10 ? '0'+(date.getHours()+1) : date.getHours()+1) + ':';
        var m = (date.getMinutes() +1 < 10 ? '0'+(date.getMinutes()+1) : date.getMinutes()+1) + ':';
        var s = (date.getSeconds() +1 < 10 ? '0'+(date.getSeconds()+1) : date.getSeconds()+1) + ' ';
        return Y+M+D+h+m+s;
      }
    },
    // 删除操作
    handleDelete(index,row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(() => {
        console.log(row);
        var data = {
            id:row.id
        }
         delete_userlist(data).then((res) => {
             if(res.code == 1){
                console.log(res);
                this.$message.success("删除成功");
                this.tableData.splice(index, 1);
                this.getData();
             }else{
                this.$message.error(res.msg);
             }
            });
        })
        .catch(() => {});
    },
    addmenu() {
      this.addVisible = true;
    },
    // 添加保存
    addmenuFun(addform) {
      console.log(this.addform);
      this.$refs[addform].validate((valid) => {
        if (valid) {
          add_userdata(this.addform).then((res) => {
            if (res.code == 1) {
              this.addVisible = false;
              this.$message.success(res.msg);
              this.getData()
            } else {
              console.log(res);
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      var editdata = {id:row.id}
      get_user_infolist(editdata).then((res) => {
        console.log(res);
        if(res.code == 1){
          this.editVisible = true;
          this.editform = res.data.user_info
          this.editform.role_id_arr = res.data.role_id_arr
        }
      })
    },
    addsmall(){
        this.$message.success( `添加子菜单`);
    },
    // 保存编辑
    saveEdit() {
      console.log(this.editform);
      edit_list(this.editform).then((res) => {
        if(res.code == 1){
            this.$message.success(res.msg);
            this.editVisible = false;
            this.getData()
        }else{
            this.$message.error(res.msg);

        }
      })
    },
    // 分页导航
    handleCurrentChange(val) {
      this.hoteldatares.page_index = val;
      console.log(this.hoteldatares.page_index)  //点击第几页
      this.getData();
    },
  },
};
</script>

<style scoped>
.text_01 {
  font-size: 13px;
  color: red;
}
.text_01 a {
  color: royalblue;
  text-decoration: underline;
  margin-left: 3px;
  margin-right: 3px;
}
.title_01 {
  margin-bottom: 20px;
}
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
